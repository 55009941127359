.dynamic-content-dialog-header{
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;
    margin-left: 24px;
}
.dynamic-content-dialog-header-icon{
    font-size: 62px;
}

.dynamic-content-dialog-label{
    font-size: 12px;
    color: #697A8B;
}

.dynamic-content-dialog-label-align{
    padding-top: 41px
}

.velocity-container {
    display: flex;
    gap: 36px;
    padding: 0px 16px;
}

.permanency-container {
    display: flex;
    gap: 36px;
    padding: 0px 16px;
}

.permanency-minimum-container {
    display: flex;
    gap: 10px;

}

.permanency-maximum-container {
    display: flex;
    gap: 10px;
}

.permanency-item {

}

input {
    height: 34px;
    width: 100%;
    border-radius: 4px;
    padding: 12px;
}

.input, .input:focus {
    border: 1px solid #2BA1AC;
    outline: none;
}

.input-error, .input-error:focus {
    border: 1px solid red;
    outline: none;
}