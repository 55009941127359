.col-sm-4 {
    margin-left: 10px;
}

.border {
    border-style: none;
}

.expander-panel.panel-blank > .panel-heading .expander-icon {
    color: black;
}

.contentArea {
    padding-left: 18px;
    padding-top: 10px;
}

.veiculo {
    padding-left: 10px;
}

.checkbox-inline {
    padding-left: 10px;
}

.form-group {
    padding-left: 10px;
}

.form-group {
    padding-left: 10px;
}

.form-group-2 {
    padding-left: -10px;
}

.btn-toolbar-cust {
    padding-left: -30px;
}

.botao-com-calcular {
    margin-top: 23px;
    border-radius: 4px;
    border: 1px;
    padding-right: 12px;
    padding-left: 12px;
    width: 100%;
}

.tolerancia {
    padding-left: 20px;
}

.checkbox-text {
    margin-bottom: 10px;
}

.Test {
    margin-top: 23px;
}

.width-220 {
    margin-left: 22px;
}

.cargaDescarga {
    padding-left: 50px;
}

.cargaDescarga-2 {
    padding-left: 50px;
}

.subTable {
    padding-left: 50px;
    width: 400px;
    padding-top: 5px;
}

.subTable-2 {
    padding-left: 50px;
    width: 400px;
}

.col-1-2 {
    margin-top: 20px;
}

.rota {
    padding-left: 50px;
}

.separator > div + div {
    border-top: 1px solid #e5ebf0;
    border-radius: 0;
}

.separator > .panel {
    margin-bottom: 0 !important;
}

.itens-container {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 20px;
}

.checkbox-container {
    margin-left: 24px;
}

.input-container {
    width: 130px;
    margin-right: 20px;
}

.slider-container {
    flex: 1;
    margin-left: 20px;
}

/*Estilização da parte simulação de custos*/
.simulador-item-container {
    padding: 0 10px;
    margin-top: -25px;
    margin-bottom: 30px;
}

.simulador-veiculo-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 15.08px;
    padding-left: 15px;
    margin-right: 15px;
    color: #697A8B;
}

.simulador-form-group1 {
    margin-bottom: 15px;
    width: 251px;
    margin-top: 10px;

}

.simulador-form-group2 {
    padding-left: -10px;
    width: 251px;
    margin-top: 10px;
}

.simulador-input {
    height: 34px;
    width: 100%;
    border-radius: 4px;
    padding: 12px;
    border: 1px solid #D0D8DE;

}

.simulador-input:focus{
    border: 1px solid #2BA1AC;
    outline: none;
}

.simulador-result-info-container {
    padding-left: 24px;
    margin-right: 24px;
}

.simulador-result-item {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    border-bottom: 1px solid #D0D8DE;
    align-items: center;
    height: 40px;
}

.simulador-item-info-result-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 17.6px;
    color: #2A3740;
}

.simulador-item-info-result-value {
    font-weight: 400;
    font-size: 16px;
    line-height: 20.11px;
    padding-right: 4px;
}

.simulador-result-item-total {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    align-items: center;
    height: 40px;
    margin-bottom: 24px;
}

.simulador-item-info-result-total {
    font-weight: 700;
    font-size: 14px;
    line-height: 17.6px;
    color: #2A3740
}

.simulador-item-info-result-total-value {
    font-weight: 700;
    font-size: 16px;
    line-height: 20.11px;
    color: #2A3740;
    margin-right: 3px;
}

.simulador-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    padding: 0 10px;
}

.simulador-col-50-left {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 8px 0 0;
}

.simulador-col-50-right {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 0 0 8px;
}

.areas-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-left:26px;
    justify-content: space-between;
    padding-right: 26px;
    border: none;
}

.area-item-block{
    display: grid;
    grid-template-columns: 5% 100%;
    gap: 15px;
    align-items: center;
}

.area-item-block-2{
    display: grid;
    grid-template-columns: 80% 5%;
    align-items: center;
    gap: 15px;
}

.area-item-icon{
    font-size: 25px;
    color: #A7AFBB;
;
}

.area-item-name{
    margin-left:6px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
}

.area-del-button{
    font-size: 20px;
    border: 0;
    background: #fff;
    color: #30B4C1;
}

.area-container-main {
    padding-left: 24px;
    margin-right: 24px;
}

.no-areas-message {
    background-color: #fff;
    color: #30B4C1;
    border: 1px solid #2BA1AC;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    font-size: 16px;
    margin: 20px 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}