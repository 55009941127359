.custom-slider .slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: var(--slider-height, 3px);
    background: linear-gradient(
            to right,
            var(--slider-filled-color, #30B4C1) 0%,
            var(--slider-filled-color, #30B4C1) var(--progress, 0%),
            var(--slider-empty-color, #E5EBF0) var(--progress, 0%),
            var(--slider-empty-color, #E5EBF0) 100%
    );
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    transition: background 0.2s ease;
}

.custom-slider .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    background: var(--slider-filled-color, #30B4C1);
    border-radius: 50%;
    border: 2px solid var(--slider-filled-color, #30B4C1);
    cursor: pointer;
}

.custom-slider .slider::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background: var(--slider-filled-color, #30B4C1);
    border-radius: 50%;
    border: 2px solid var(--slider-filled-color, #30B4C1);
    cursor: pointer;
}

.custom-slider .slider::-ms-thumb {
    width: 16px;
    height: 16px;
    background: var(--slider-filled-color, #30B4C1);
    border-radius: 50%;
    border: 2px solid var(--slider-filled-color, #30B4C1);
    cursor: pointer;
}
