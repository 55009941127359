.col-sm-4 {
  margin-left: 10px;
}

.form-group {
  padding-left: 10px;
}

.form-group {
  padding-left: 10px;
}

.form-group-2 {
  padding-left: -10px;
}

.btn-toolbar-cust {
  padding-left: -30px;
}

.botao-com-calcular {
  margin-top: 23px;
  border-radius: 4px;
  border: 1px;
  padding-right: 12px;
  padding-left: 12px;
}

.tolerancia {
  padding-left: 20px;
}

.checkbox-text {
  margin-top: 10px;
}

.Test {
  margin-top: 23px;
}
.width-220 {
  margin-left: 22px;
}
.cargaDescarga {
  padding-left: 50px;
}

.cargaDescarga-2 {
  padding-left: 50px;
}
.subTable {
  padding-left: 50px;
  width: 400px;
  padding-top: 5px;
}

.subTable-2 {
  padding-left: 50px;
  width: 400px;
}

.col-1 {
  margin-top: 22px;
}

.col-1-2 {
  margin-top: 20px;
}
.rota {
  padding-left: 50px;
}

.separator > div + div {
  border-top: 1px solid #e5ebf0;
  border-radius: 0;
}

.separator > .panel {
  margin-bottom: 0 !important;
}
