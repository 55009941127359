.custom-input-select {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 34px;
    max-width: 300px;
}

.select-input {
    width: 100%;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 400;
    color: #4C5667;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #fff;
    appearance: none;
}

.select-input:disabled {
    background: #f5f5f5;
    cursor: not-allowed;
}

.select-input:focus {
    border: 1px solid #2BA1AC;
    outline: none;
}

.select-icon {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    font-size: 14px;
    color: #4C5667;
    pointer-events: none;
}